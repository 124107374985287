@media screen and (max-width: 480px) {
  .footer{
    position: fixed;
    text-align: center;
    color: #FFFFFF;
    width: 100%;
    bottom: 0px;
    height: 20px;
    padding-bottom: 3px;  
  }

  h1{
    font-weight:500;
    text-shadow: 5px 5px 5px #070707;
    color:white;  
    font-size: 26px;
    text-indent: 2rem;
  }
  
  h2{
    color:white;
    font-weight:500;
    font-size: 22px;
    text-indent: 2rem;
    padding-left: 5px;
    padding-right: 5px;
  }
  
  h3{
    color:black;
    font-weight:500;
    font-size: 16px;
  }

  h4{
    font-weight:500;
    text-shadow: 5px 5px 5px #070707;
    color:white;  
    font-size: 22px;
    text-indent: 2rem;
  }

  h5{
    color:white;
    font-weight:400;
    font-size: 18px;  
  }

  .logoImg{
    height:90px;    
  }

  .menubar{
    display:flex;
    width: 100vw;
    align-content: center;
  }

  .media{
    position: absotulte;
    left: 80%;
    width: 70px;
    top:15;    
  }

  .nav {    
      color: white;
      align-items: center;
      gap: 1rem;
      padding: 0 1rem;
      align-items: center;
      width:100%;
      margin-left: auto;
      margin-right: auto; 
  } 
 
  .nav a {
      color: inherit;
      text-decoration: none;
      font-size: 22px;
      font-weight: lighter;
      font-family: 'Crimson Text', serif;
  }

  .layoutBackground{
    position:absolute;
    width: 100%;
    height: calc(100vh - 180px);
    background-color: rgb(255, 255, 255);  
    opacity: 0.2;  
    z-index: 0;
    left: 0px;
  }
  
  .content{
    display: inline-flex;
    width: 100%;
    margin-top: 140px;
    height: calc(100vh - 180px);
    z-index: 1;
  }  

  .groupSelectStyle{
    border-style: none;
    margin: 0 5px;
    cursor: pointer;
    font-size: 20px;
    text-align: center;
    text-shadow: 5px 5px 5px #424242;
    color:#fff;
    padding: 0px 10px 0px 10px;
    background: transparent;
    font-family: 'Crimson Text', serif;
    z-index: 1;     
  }

  .dotsStylesContainer{
    position: absolute;
    display: flex;
    width: 100%;
    height: 25px;
    bottom: 0px;
    z-index: 1;  
    justify-content: center;
    align-items: center;
    word-wrap: normal;
  }

  .dotStyle{  
    margin: 0 3px;
    cursor: pointer;
    font-size: 18px;
    color:#fff;
    text-shadow: 2px 2px 2px #5a5a5a;
    z-index: 1; 
  }

  .dotStyle:hover{  
    color:#ff0505;
    font-size: 14px;
  }
}
  