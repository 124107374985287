@media screen and (max-height: 900px) {
  .footer{
    position: fixed;
    text-align: center;
    color: #FFFFFF;
    width: 100%;
    bottom: 26px;
    height: 10px;
    padding-bottom: 5px;  
  }

  h1{
    font-weight:500;
    text-shadow: 5px 5px 5px #070707;
    color:white;  
    font-size: 24px;
    text-indent: 2rem;
  }
  
  h2{
    color:white;
    font-weight:500;
    font-size: 10px;
    text-indent: 2rem;
    padding-left: 5px;
    padding-right: 5px;
  }
  
  h3{
    color:black;
    font-weight:500;
    font-size: 8px;
  }

  h4{
    font-weight:500;
    text-shadow: 5px 5px 5px #070707;
    color:white;  
    font-size: 10px;
    text-indent: 2rem;
  }

  h5{
    color:white;
    font-weight:400;
    font-size: 10px;
  }

  .logoImg{
    height:50px;    
  }
 
  .menubar{
    display:flex;
    width: 100vw;
    align-content: center;
  }

  .media{
    position: absotulte;
    padding: 10px;  
    float: right;         
    width: 140px;    
  }
  
  .nav {    
    color: white;
    width:500px;
    margin-left: auto;
    margin-right: auto; 
  } 

  .nav a {
    color: inherit;
    text-decoration: none;
    font-size: 22px;
    font-weight: lighter;
    font-family: 'Crimson Text', serif;
  }
    
  .layoutBackground{
    position:absolute;
    width: 100%;
    height: calc(100vh - 135px);
    background-color: rgb(255, 255, 255);  
    opacity: 0.2;  
    z-index: 0;
    left: 0px;
  }
    
  .content{
    display: inline-flex;
    width: 100%;
    margin-top: 90px;
    height: calc(100vh - 135px);
    z-index: 1;
  }  

  .groupSelectStyle{
    border-style: none;
    margin: 0 5px;
    cursor: pointer;
    font-size: 10px;
    text-align: center;
    text-shadow: 5px 5px 5px #424242;
    color:#fff;
    padding: 0px 10px 0px 10px;
    background: transparent;
    font-family: 'Crimson Text', serif;
    z-index: 1;     
  }

  .contactbar{ 
    position:flex;
    text-align: center;
    margin:auto;
    vertical-align: middle;
    padding: 0px 5px 0px 5px;  
    z-index: 1;
    overflow: auto;
  }

  .dotsStylesContainer{
    position: absolute;
    display: flex;
    width: 100%;
    height: 25px;
    bottom: 0px;
    z-index: 1;  
    justify-content: center;
    align-items: center;
  }

  .dotStyle{  
    margin: 0 3px;
    cursor: pointer;
    font-size: 20px;
    color:#fff;
    text-shadow: 2px 2px 2px #5a5a5a;
    z-index: 1; 
  }
  
  .dotStyle:hover{  
    color:#ff0505;
    font-size: 24px;
  }
}