@import url('https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap');

* {
  padding: 0px;
  margin: 0px;  
}

body {
  font-family: 'Crimson Text', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;    
  background-color:white;  
  overflow-y: hidden;
  overflow-x: hidden;  
}

code {
  font-family: 'Crimson Text', serif;
}

h1{
  font-weight:500;
  text-shadow: 5px 5px 5px #070707;
  color:white;  
  font-size: 32px;
  text-indent: 2rem;
}

h2{
  color:white;
  font-weight:500;
  font-size: 24px;
  text-indent: 2rem;
}

h3{
  color:black;
  font-weight:500;
  font-size: 20px;
}

h4{
  font-weight:500;
  text-shadow: 5px 5px 5px #070707;
  color:white;  
  font-size: 24px;
  text-indent: 2rem;
}

h5{
  color:white;
  font-weight:400;
  font-size: 18px;
}

.layout{
  position:absolute;
  top:0;
  left:0;
  z-index: 0;
  overflow-y: hidden;
  overflow-x: hidden; 
  height: 100vh;
  width: 100vw;
}

.layoutImg{
  width: 100vw;
  height: 100vh;
  object-fit:cover;
  z-index: 0;
}

.menubar{
  display:flex;
  width: 100vw;
  align-content: center;  
}

.media{
  position: absolute;
  padding: 35px 20px 0px 0px;      
  left: calc(100vw - 160px);         
  width: 150px;  
  top: 0px;    
}

.nav {    
  color: white;
  align-items: center;
  gap: 2rem;
  padding: 0 1rem;
  align-items: center;    
  width:800px; 
  margin-left: auto;
  margin-right: auto;      
}

.nav ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.nav a {
  color: inherit;
  text-decoration: none;
  font-size: 36px;
  font-weight:lighter;
  font-family: 'Crimson Text', serif;
}

.nav a:hover{
  animation: menuShadow 2s 
}

@keyframes menuShadow {
  from {text-shadow: 5px 5px 5px #424242;}
  to {text-shadow: 5px 5px 5px #070707;}
}

.header{
  position: fixed;
  top: 0px;
  color: #FFFFFF;
  width: 100%;      
}

.footer{
  position: fixed;
  text-align: center;
  color: #FFFFFF;
  width: 100%;
  bottom: 0px;
  height: 15px;
  padding-bottom: 15px;  
}

.logo{
  display: flex;  
  top: 0px;
  width: 100%;
  justify-content: center;
}

.logoImg{
  height:100px;
  padding-top: 5px;
}

.media div {
  display:inline-block;  
}

.media div a {
  color: white;
  padding-left: 30px;
  width: 20px;
}

.layoutBackground{
  position:absolute;
  width: 100%;
  height: calc(100vh - 210px);
  background-color: rgb(255, 255, 255);  
  opacity: 0.2;
  left: 0px;
  z-index: 0;
}

.content{
  display:inline-flex;
  justify-content: center;
  left: 0;     
  margin-top: 170px;
  width: 100%;
  height: calc(100vh - 210px);
  z-index: 1;  
}

.wrapper1 { 
  position:relative;
  margin:auto;
  height:100%;
  width: 1800px;
  align-content: center;    
}

.imageContainer{  
  height:100%;
  width:100%;  
  z-index: 0;  
}

.slideImage{
  position:relative;
  object-fit:contain;
  width: 100%;
  height: 100%;
  z-index: 0;  
}

.groupContainer{
  position: absolute;
  display: flex;
  width: 100%;
  height: 35px;
  top: 10px;
  z-index: 1;    
  justify-content: center;
  align-items: center;
}

.groupSelectStyle{
  border-style: none;
  margin: 0 5px;
  cursor: pointer;
  font-size: 24px;
  text-align: center;
  text-shadow: 5px 5px 5px #424242;
  color:#fff;
  padding: 0px 10px 0px 10px;
  background: transparent;
  font-family: 'Crimson Text', serif;
  z-index: 1; 
}

.groupSelectStyle option{
  background-color:darkgray;  
}

.dotsStylesContainer{
  position: absolute;
  display: flex;
  width: 100%;
  height: 35px;
  bottom: 0px;
  z-index: 1;  
  justify-content: center;
  align-items: center;
}

.dotStyle{  
  margin: 0 3px;
  cursor: pointer;
  font-size: 26px;
  color:#fff;
  text-shadow: 2px 2px 2px #5a5a5a;
  z-index: 1; 
}

.dotStyle:hover{  
  color:#ff0505;
  font-size: 34px;
}

.videoContainer{
  position:relative;
  width:100%;
  height:100%;  
  margin: auto;
  z-index: 0;  
}

.videoContainer iframe{
  position: absolute; 
  width:100%;
  height:100%;
  top:0;
  left:0;
}

.contactbar{ 
  position:flex;
  text-align: center;
  margin:auto;
  vertical-align: middle;
  padding: 0px 5px 0px 5px;  
  z-index: 1;
}

.contactbar a{
  display:inline-flex;
  color:#FFFFFF;
  text-decoration: none;  
  align-items: center;
  z-index: 1;
}
